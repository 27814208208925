<template>
  <div class="content-tree text-xs overflow-y-auto pb-4">
    <div class="p-2 mt-2 text-gray-100 text-center">
      <div class="flex justify-center">
        <img
          src="@assets/img/default/content-sandbox.svg"
          alt="Upgrade to Previz App"
          class="pt-6 px-16 pb-2 hidden lg:block max-w-xs"
        >
      </div>

      <div class="mx-auto max-w-sm select-none">
        <div class="p-2 m-2">
          <div class="flex items-center justify-center">
            <fa-icon
              icon="circle-info"
              class="fad text-blue-500 mr-4 hidden lg:flex"
              size="lg"
            />
            <p class="text-sm leading-5 text-blue-400">
              Content area not available in Sandbox.
            </p>
          </div>
        </div>
      </div>

      <div class="callouts flex flex-col justify-center items-center my-1 mx-2 lg:mx-5 select-none">
        <div class="text-base lg:text-xl font-bold pt-5 my-3 border-dotted border-t">
          Upload and preview all of your project content
        </div>

        <div class="callout flex max-w-md my-4">
          <fa-icon
            icon="bolt"
            class="mr-2 fad fa-fw mt-2 mr-6 text-blue-500"
            size="2x"
          />
          <div class="flex flex-col text-left">
            <div class="text-base lg:text-lg font-bold text-gray-300">
              Fast, Simple &amp; Intuitive
            </div>
            <div class="text-gray-400">
              Simply drag and drop content into the timeline to create sequences.
            </div>
          </div>
        </div>

        <div class="callout flex max-w-md my-4">
          <fa-icon
            icon="map"
            class="mr-2 fad fa-fw mt-2 mr-6 text-blue-500"
            size="2x"
          />
          <div class="flex flex-col text-left">
            <div class="text-base lg:text-lg font-bold text-gray-200">
              Map Your Content
            </div>
            <div class="text-gray-300">
              The advanced mapping features in Previz enable you to easily map content onto multiple screens. See your vision come to life.
            </div>
          </div>
        </div>

        <div class="callout flex max-w-md mt-4 mb-3">
          <fa-icon
            icon="users"
            class="mr-2 fad fa-fw mt-2 mr-6  text-blue-500"
            size="2x"
          />
          <div class="flex flex-col text-left">
            <div class="text-base lg:text-lg font-bold text-gray-200">
              Collaborate in Real Time
            </div>
            <div class="text-gray-300">
              Keep everyone in the loop by sharing your Previz project with your team, production partners and your client.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mx-2 lg:mx-5">
      <a
        href="https://next.previz.co/join"
        class="btn positive inline-block mt-2"
      >Get started for free
        <fa-icon
          icon="arrow-right"
          class="fa-fw"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContentTreeSandbox'
}
</script>
